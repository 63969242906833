
// components
import { IonPage, IonContent, IonGrid, IonCol, IonRow, IonButtons, IonButton, IonIcon, } from '@ionic/vue';
import CheckoutForm from '@/components/order/CheckoutForm.vue';

// composables
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default {
  name: 'CheckoutPage',
  components: { IonPage, IonContent, IonGrid, IonCol, IonRow, IonButtons, IonButton, IonIcon,
                CheckoutForm, },
  setup() {
    const { t, locale } = useI18n();
    const route = useRoute();
    const { orderId } = route.params; // for editing existing sticker

    return {
      // variable
      orderId,

      // methods
      t,
    }
  }
}
